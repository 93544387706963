@import url('https://fonts.googleapis.com/css?family=Poppins|Work+Sans');
@import 'animation';

$primary:rgba(0,172,193,1);
$secondary:rgba(84,58,183,1);
$dark: #1a222c;
$badge-primary:#5fc383;
$badge-secondary:#68b0f1;
$cut: 768px;
$hero-font: 'Work Sans';
$body-font: 'Poppins';


#hero {
  background: linear-gradient(60deg, $secondary 0%, $primary 100%);
  display: flex;
  vertical-align: bottom;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .avatar {
    vertical-align: middle;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid white;
  }

  h1 {
    font-size: 2.5rem;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

  h1 span {
    margin-bottom: 10px;
  }
}

.header {
  font-family: $hero-font, sans-serif;
  z-index: 1;
  text-align: center;
  color:#FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .site-title {
    font-size: 50px;
    display: block;
    line-height: 1;
    color: #FFF;
    @media only screen and (min-width:$cut){
      margin: 0;
      font-size: 76px;
    }
  }
  .site-description {
    font-size: 20px;
    display: block;
    line-height: 1;
    color: #FFF;
    margin-top: 10px;
    @media only screen and (min-width:$cut){
      font-size: 34px;
    }
  }
}

.header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  a {
    // no borders
    outline: 0 !important;
    border: none !important;
    outline-style: none !important;
    -moz-outline-style: none !important;

    padding: none;
    margin: none;
  }
  .icon {
    text-align: center;
    color: #FFF;
    width: 30px;
    height: 30px;
    font-size: 30px;
    padding: 10px;
    margin: 5px;
    border-radius: 50%;
    border: 2px solid #FFF;
    transition: all .5s;
    &:hover, &:active {
      color: $dark;
      background: #FFF;
    }
    @media only screen and (min-width:$cut) {
      width: 30px;
      height: 30px;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.down {
  color: #FFF;
  position: absolute;
  bottom: 40px;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
  // no borders
  outline: 0 !important;
  border: none !important;
  outline-style: none !important;
  -moz-outline-style: none !important;
  .icon {
      position: absolute;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      fill: #fff;
      -webkit-animation: pulse 1.3s infinite;
      animation: pulse 1.3s infinite;
  }
}

* {
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.waves-container {
  width: 100%;
  position: absolute;
  bottom: -2vh;
}

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: 1.5;
  color: #555;

  font-family: $body-font, serif;
  font-weight: 400;
}

h1, h2, h3, h4, stong {
  line-height: 1.25;
  color: #333;
}

h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1rem;
}
ul, ol {
  margin: 1em 0;
  padding-left: 40px;
}
p, figure {
  margin: 1em 0;
}
a img {
  border: none;
}
sup, sub {
  line-height: 0;
}


// #about
#about {
  padding-top:10px;
  font-size: 120%;
  
  width: 90%;
  margin: 0 auto;
  max-width: 960px;
  @media only screen and (min-width: $cut) {
    width: 80%;
  }
  svg {
    width: 60px !important;
    height: auto;
  }

  .user-details {
    margin-bottom: 2.5em
  }

  .cv-item {
    border-bottom: 1px solid rgba(0,0,0,.15);
    margin-bottom: 3em;
    padding-bottom: 3em;

    .icon-bar {
      font-size: 30px;
    }
    .icon-bar-larger {
      font-size: 50px;
    }
    // todo delete
    .icon {
      text-align: center;
      color: #FFF;
      width: 20px;
      height: 20px;
      font-size: 20px;
      padding: 10px;
      margin: 5px;
    }
    h2, h3 {
      font-weight: 400;
    }

    .badge {
      color: white;
      font-size: 12px;
      border-radius: 3px;
      display: inline-block;
      white-space: no-wrap;
      margin-left: 14px;
      padding: 5px 15px;
      vertical-align: middle;
    }
    .badge-primary {
      background: $badge-primary;
    }
    .badge-secondary {
      background: $badge-secondary;
    }
  }

  // Wait for font awesome to load before showing
  body {
    display: none;
  }

   body.fontawesome-i2svg-complete {
    display: initial;
  }
}